import { userTypes } from 'redux/actionTypes/userTypes';

export const setUser = (userArray: any) => {
  return {
    type: userTypes.set,
    payload: userArray,
  };
};

export const setLogged = (logged: boolean) => {
  return {
    type: userTypes.setLogged,
    payload: logged,
  };
};
