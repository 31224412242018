export const apiMap = {
  authenticate: '/api/auth/sign-in',
  checkExistingEmail: '/api/auth/check-existing-email',
  submitRegistration: '/api/auth/submit-registration',
  sendVerifyPhoneCode: '/api/auth/send-phone-code',
  validatePhone: '/api/auth/validate-phone',
  forgotPassword: '/api/auth/reset-password-request',
  confirmPassword: '/api/auth/confirm-password',
  logout: '/api/auth/logout',
  checkResetToken: '/api/auth/check-reset-token',

  formDemo: '/api/cargoes-finance-demo',
  erpDemo: '/api/searates-erp-demo',

  surveyConfig: '/api/survey/cargoes-finance',
  survey: {
    config: '/api/survey/config',
    tags: '/api/survey/tags',
    save: '/api/survey/save',
  },

  user: '/api/user',

  initialAuthenticate: '/api/initialAuthenticate',

  directory: {
    filter: '/api/directory/filter',
    members: '/api/directory/members',
  },

  contact: '/api/company/contact-us',

  feedback: {
    expectations: '/api/feedback/expectations',
    experience: '/api/feedback/experience',
  },

  blacklist: {
    getAllCompanies: '/api/blacklist/get-all-companies',
    getOneCompany: (companyQuery: string) => `/api/blacklist/get-one-company?query=${companyQuery}`,
    getCities: (countryCode: string) => `/api/blacklist/get-cities?country_code=${countryCode}`,
    getAllCountries: '/api/blacklist/get-all-countries',
    getAllManagers: '/api/blacklist/get-all-managers',
    addNewCompany: '/api/blacklist/blacklist-item',
    removeCompany: (companyId: number) => `/api/blacklist/blacklist-delete?id=${companyId}`,
    editCompany: () => `/api/blacklist/edit-company`,
    removeFile: (fileId: number) => `/api/blacklist/blacklist-delete-file?id=${fileId}`, // done
  },

  topArticles: {
    blogItems: '/api/articles/blog-items',
    addLike: '/api/articles/add-like',
    addView: '/api/articles/add-view',
  },

  admin: {
    articleCreate: '/api/admin/article-create',
    uploadFile: '/api/admin/upload-file',
    deleteArticle: '/api/admin/article-delete',
  },

  help: {
    faqData: '/api/help/formatted-help',
    item: '/api/help/item',
    delete: '/api/help/delete',
  },
};
