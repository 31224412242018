import { BlacklistTypes } from 'redux/actionTypes/blacklistTypes';
import { ICompany, IFile } from 'sections/Resources/BlacklistPage/interfaces';

const initialState = {
  companies: [],
  filteredCompanies: [],
  companyToEdit: [],
  reasons: [],
  countries: [],
  managers: [],
};

export const blacklistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BlacklistTypes.setAllCompanies:
      return {
        ...state,
        companies: action.payload,
      };
    case BlacklistTypes.setFilteredCompanies:
      return {
        ...state,
        filteredCompanies: action.payload,
      };
    case BlacklistTypes.setOneCompany:
      const result = [...state.companies];
      // @ts-ignore
      result.unshift(action.payload);

      return {
        ...state,
        companies: result,
      };
    case BlacklistTypes.setCompanyToEdit:
      return {
        ...state,
        companyToEdit: state.companies.filter((item: any) => item.id === action.payload),
      };
    case BlacklistTypes.editCompany:
      return {
        ...state,
        companies: state.companies.map((item: any) => (item.id === action.payload.id ? action.payload : item)),
      };
    case BlacklistTypes.removeCompany:
      return {
        ...state,
        companies: state.companies.filter((item: any) => item.id !== action.payload),
      };
    case BlacklistTypes.setReasons:
      return {
        ...state,
        reasons: action.payload,
      };
    case BlacklistTypes.setCountries:
      return {
        ...state,
        countries: action.payload.sort((a: any, b: any) => a.country.localeCompare(b.country)),
      };
    case BlacklistTypes.setManagers:
      return {
        ...state,
        managers: action.payload,
      };
    case BlacklistTypes.removeFile:
      // const companyIndex: number = state.companies.findIndex((company: ICompany) => company.id === action.payload.companyId);
      // if (companyIndex === -1) return;
      // const foundCompany: ICompany = state.companies[companyIndex];

      // let newFiles: IFile[] = foundCompany.files.filter((file: IFile) => file.id !== action.payload.fileId);

      // if (action.payload.fileId === undefined) {
      //     // @ts-ignore
      //     newFiles = foundCompany.files.filter((file: any) => file.name !== action.payload.fileName);
      // } else {
      //     // @ts-ignore
      //     newFiles = foundCompany.files.filter((file: IFile) => file.id !== action.payload.fileId);
      // }

      return {
        ...state,
        // @ts-ignore
        // companies: [...state.companies, state.companies[companyIndex].files = newFiles]
        companies: state.companies.map((company: ICompany) => {
          if (company.id === action.payload.companyId) {
            const files = company.files.filter((file: IFile) => file.id !== action.payload.fileId);
            return { ...company, files };
          }
          return company;
        }),

        //@ts-ignore
        companyToEdit: state.companyToEdit.map((company: ICompany) => {
          const files = company.files.filter((file: IFile) => file.id !== action.payload.fileId);
          return { ...company, files };
        }),
      };
    case BlacklistTypes.addNewFile:
      const company_index: number = state.companies.findIndex(
        (company: ICompany) => company.id === action.payload.companyId
      );
      if (company_index === -1) return;
      const found_company: ICompany = state.companies[company_index];
      const new_files: IFile[] = [];

      action.payload.filesArray.forEach((payloadFile: any) => {
        if (found_company.files.length) {
          found_company.files.forEach((companyFile: any) => {
            if (payloadFile.name !== companyFile.name) {
              new_files.push(payloadFile);
            } else {
              return;
            }
          });
        } else {
          new_files.push(payloadFile);
        }
      });

      return {
        ...state,
        // @ts-ignore
        companies: [...state.companies, (state.companies[company_index].files = new_files)],
        // @ts-ignore
        // companies: [...state.companies, state.companies[company_index].files = [...state.companies[company_index].files, ...new_files]]
      };
    default:
      return state;
  }
};
