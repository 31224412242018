import { membersTypes } from 'redux/actionTypes/membersTypes';
import { Member } from 'sections/Directory/DirectoryPage/components/utils/type';

interface IInitialStateMembers {
  members: Member[];
  membersVisible: Member[];
  membersFirst: Member[];
  page: boolean;
}

const initialState = {
  members: [],
  membersVisible: [],
  membersFirst: [],
  page: true,
};

export const membersReducer = (state: IInitialStateMembers = initialState, action: any) => {
  switch (action.type) {
    case membersTypes.set:
      return {
        ...state,
        members: action.payload,
        membersVisible: action.payload,
        membersFirst: [],
      };
    case membersTypes.filter:
      return {
        ...state,
        membersVisible: action.payload,
      };
    case membersTypes.setFirst:
      return {
        ...state,
        membersFirst: action.payload,
      };
    case membersTypes.remove:
      return {
        ...state,
        members: [],
      };
    case membersTypes.page:
      return {
        ...state,
        page: !state.page,
      };
    default:
      return state;
  }
};
