export const BlacklistTypes = {
    setAllCompanies: 'BLACKLIST/SET_ALL_COMPANIES',
    setOneCompany: 'BLACKLIST/SET_ONE_COMPANY',
    setCompanyToEdit: 'BLACKLIST/SET_COMPANY_TO_EDIT',
    setFilteredCompanies: 'BLACKLIST/SET_FILTERED_COMPANIES',
    editCompany: 'BLACKLIST/EDIT_COMPANY',
    removeCompany: 'BLACKLIST/REMOVE_COMPANY',

    setReasons: 'BLACKLIST/SET_ALL_REASONS',

    setCountries: 'BLACKLIST/SET_ALL_COUNTRIES',

    setManagers: 'BLACKLIST/SET_ALL_MANAGERS',

    removeFile: 'BLACKLIST/REMOVE_FILE',
    addNewFile: 'BLACKLIST/ADD_NEW_FILE',
}
