import { blogTypes } from 'redux/actionTypes/blogTypes';

const initialState = {
  posts: [],
  tags: [],
};

export const blogReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case blogTypes.set:
      return {
        ...state,
        posts: action.payload,
      };
    case blogTypes.setTag:
      return {
        ...state,
        tags: action.payload,
      };
    default:
      return state;
  }
};
