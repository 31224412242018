import BaseScheme, {BaseSchemeType} from "./BaseScheme";
import OrganizationScheme from "./OrganizationScheme";
import WebPageScheme from "./WebPageScheme";

type SchemeType = BaseSchemeType & {
}

class Scheme extends BaseScheme<SchemeType> {
  constructor(private path:string) {
    super(path);
  }

  organization = () => new OrganizationScheme(this.path, true)
  webPage = () => new WebPageScheme(this.path, true)
}

export default Scheme;
