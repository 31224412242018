import BaseScheme, {BaseSchemeType} from "./BaseScheme";

export type OrganizationSchemeType = BaseSchemeType & {
  '@type': 'Organization'
  name?: string
  url?: string
  logo?: string
  image?: string
  description?: string
}

class OrganizationScheme extends BaseScheme<OrganizationSchemeType> {
  constructor(path: string, base?: boolean) {
    super(path, base);
    super.set('@type', 'Organization');
  }
}

export default OrganizationScheme
