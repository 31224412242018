import BaseScheme, {BaseSchemeType} from "./BaseScheme";
import BreadcrumbListScheme, {Breadcrumb, BreadcrumbListSchemeType} from "./BreadcrumbListScheme";

export type WebPageSchemeType = BaseSchemeType & {
  "@type": "WebPage"
  "@id": string
  alternateName: string
  description: string
  image?: string
  inLanguage: 'English'
  isPartOf: 'https://www.df-alliance.com'
  keywords?: string[]
  sameAs?: string[]
  significantLink?: string
  breadcrumb: BreadcrumbListSchemeType
}

class WebPageScheme extends BaseScheme<WebPageSchemeType> {
  constructor(path: string, base?: boolean) {
    super(path, base);

    super.set("@type", "WebPage");
    super.set('@id', WebPageScheme.url(path));
    super.set('inLanguage', 'English');
    super.set('isPartOf', 'https://www.df-alliance.com');

    super.set('sameAs', [
      "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
      "https://twitter.com/D_F_Alliance",
      "https://www.linkedin.com/company/digital-freight-alliance"
    ]);
  }

  setBreadcrumb(value: Breadcrumb[]) {
    super.set('breadcrumb', new BreadcrumbListScheme()
      .setItemListElement(value)
      .getObject());
    return this;
  }
}

export default WebPageScheme
