import { userTypes } from 'redux/actionTypes/userTypes';

const initialState = {
  user: [],
  logged: false,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case userTypes.set:
      return {
        ...state,
        user: action.payload,
      };
    case userTypes.setLogged:
      return {
        ...state,
        logged: action.payload,
      };
    default:
      return state;
  }
};
