import { Record } from 'utils/types';
import { FormData } from 'sections/Directory/DirectoryPage/components/Filter';
import { Member } from 'sections/Directory/DirectoryPage/components/utils/type';

export enum MembersStateTypes {
  getMembers = 'MEMBERS_STATE/GET_MEMBERS',
  setMembers = 'MEMBERS_STATE/SET_MEMBERS',
  setFilter = 'MEMBERS_STATE/SET_FILTER',
  setPage = 'MEMBERS_STATE/SET_PAGE',
}

type Options = {
  [key: string]: {
    total: number;
    pages: number;
  };
};

export type MemberResponse = {
  items: Member[];
  limit: number;
  page: number;
  total_items: number;
  total_pages: number;
};

export type MembersStateInitialState = {
  asyncState: Record<string, boolean[]>;
  state: Record<string, Member[][]>;
  filter: FormData;
  list: Member[];
  loaded: boolean;
  page: number;
  options: Options;
};
