import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { membersReducer } from 'redux/reducers/membersReducer';
import { userReducer } from 'redux/reducers/userReducer';
import { blogReducer } from 'redux/reducers/blogReducer';
import { logReducer } from 'error-handler/redux/reducers/logReducer';
import { membersStateReducer } from 'redux/reducers/membersState.reducer';
import { blacklistReducer } from 'redux/reducers/blacklistReducer';

const reducers = combineReducers({
  membersData: membersReducer,
  userData: userReducer,
  blogData: blogReducer,
  logData: logReducer,
  membersState: membersStateReducer,
  blacklistReducer,
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
