import { MembersStateInitialState, MembersStateTypes } from 'redux/actionTypes/membersState.types';
import { stringify } from 'utils/helper';
import { Action } from 'utils/types';

const initialState: MembersStateInitialState = {
  asyncState: {},
  state: {},
  filter: { text: '', country: '', city: '', status: '', service: '' },

  list: [],
  page: 1,
  loaded: true,
  options: {
    '': {
      total: 0,
      pages: 0,
    },
  },
};

export const membersStateReducer = (state: MembersStateInitialState = initialState, action: Action<any>) => {
  const key: string = stringify(state.filter);
  switch (action.type) {
    case MembersStateTypes.setFilter:
      return {
        ...state,
        filter: action.payload,
      };

    case MembersStateTypes.setPage:
      let result = {
        ...state,
        page: action.payload,
      };
      if (state.asyncState[key] && state.asyncState[key][action.payload - 1]) {
        result.loaded = true;
      }

      if (state.state[key] && state.state[key][action.payload - 1]) {
        result.list = state.state[key][action.payload - 1];
      }

      return result;

    case MembersStateTypes.getMembers:
      const pageNum = action.payload;

      const list = { ...state.state };
      const asyncState = { ...state.asyncState };

      if (!asyncState[key]) asyncState[key] = [];
      if (!list[key]) list[key] = [];

      asyncState[key][pageNum - 1] = true;

      return {
        ...state,
        asyncState,
        state: list,
      };

    case MembersStateTypes.setMembers:
      const { data, page, key: keyName } = action.payload;

      const modifiedAsyncState = { ...state.asyncState };
      const modifiedState = { ...state.state };

      modifiedAsyncState[keyName][page - 1] = false;
      modifiedState[keyName][page - 1] = data.items;
      const _keyName: string = keyName;
      return {
        ...state,
        asyncState: modifiedAsyncState,
        state: modifiedState,
        loaded: page === state.page ? false : state.loaded,
        list: page === state.page ? data.items : state.list,
        options: { ...state.options, [_keyName]: { total: data.total_items, pages: data.total_pages } },
      };

    default:
      return state;
  }
};
