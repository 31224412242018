import { logTypes } from 'error-handler/redux/actionTypes/logTypes';

const initialState: { logs: any[] } = {
  logs: [],
};

export const logReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case logTypes.add:
      return {
        ...state,
        logs: [...state.logs, action.payload],
      };
    default:
      return state;
  }
};
